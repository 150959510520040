import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"

import {
  Title,
  Input,
  Columns,
  Column,
  Select,
  Field,
  Control,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

import { isSSR } from "../../../components/Script/script"

const GoogleAds = React.lazy(() => import("../../../components/ADS/ADS"))

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Reverse Percentages",
    link: "/reverse-percentages-calculator/",
  },
]
const seeAlsoArray = [
  "/percentage-calculator",
  "/capsule-volume-and-area-calculator",
  "/cone-volume-and-area-calculator",
  "/conical-frustum-volume-and-area-calculator",
  "/cube-volume-and-area-calculator",
  "/cylinder-volume-and-area-calculator",
]

function RevercePercentageCalculator(props) {
  let [amount, setamount] = useState("")
  let [percentage, setpercentage] = useState("")
  let [percentageType, setpercentageType] = useState("increase")
  let [result, setResult] = useState("")
  let [accuracy, setaccuracy] = useState("nearest")

  const amountC = e => {
    setamount(e.target.value)
    claculatePercentage(e.target.value, percentage, percentageType, accuracy)
  }
  const percentageC = e => {
    setpercentage(e.target.value)
    claculatePercentage(amount, e.target.value, percentageType, accuracy)
  }
  const percentageTypeC = e => {
    setpercentageType(e.target.value)
    claculatePercentage(amount, percentage, e.target.value, accuracy)
  }
  const accuracyC = e => {
    setaccuracy(e.target.value)
    claculatePercentage(amount, percentage, percentageType, e.target.value)
  }

  const claculatePercentage = (amount, percentage, Type, accuracy) => {
    let perc
    if (Type === "increase") {
      perc = 100 + Number(percentage)
    } else {
      perc = 100 - Number(percentage)
    }
    let step2 = perc / 100
    let res = Number(amount) / step2
    if (accuracy === "max") {
      setResult(res)
    } else {
      setResult(res.toFixed(Number(accuracy)))
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Reverse Percentages Calculator"
        description="Calculate Reverse Percentages, the percentage decrease, and percentage increase, and learn the formula of percentage decrease/increase."
        keywords={[
          "Calculate Reverse Percentages, Reverse Percentages, Percentages Calculator, calculate percentage increase, calculate percentage decrease",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Reverse Percentages Calculator</Title>
        <Columns>
          <Column>
            <FlexDiv maxWidth="500px">
              <Field>
                <Control>
                  <StaticButton>Amount</StaticButton>
                </Control>
                <Control className="expandedWidth">
                  <Input
                    radius="0"
                    className="borderedRight"
                    type="number"
                    name="amount"
                    aria-label="amount"
                    value={amount}
                    onChange={amountC}
                    min="1"
                    required
                  />
                </Control>
              </Field>

              <Field>
                <Control>
                  <StaticButton>Percentage type</StaticButton>
                </Control>
                <Control className="expandedWidth">
                  <Select>
                    <select
                      aria-label="percentage-type"
                      className="staticSelect"
                      value={percentageType}
                      onChange={percentageTypeC}
                      onBlur={percentageTypeC}
                    >
                      <option value="increase">Increase</option>
                      <option value="decrease">Decrease</option>
                    </select>
                  </Select>
                </Control>
              </Field>

              <Field>
                <Control>
                  <StaticButton>Percentage (%)</StaticButton>
                </Control>
                <Control className="expandedWidth">
                  <Input
                    radius="0"
                    className="borderedRight"
                    type="number"
                    name="percentage"
                    aria-label="percentage"
                    min="0"
                    value={percentage}
                    onChange={percentageC}
                    required
                  />
                </Control>
              </Field>

              <Field>
                <Control>
                  <StaticButton>Accuracy</StaticButton>
                </Control>
                <Control className="expandedWidth">
                  <Select>
                    <select
                      aria-label="accuracy"
                      className="staticSelect"
                      value={accuracy}
                      onChange={accuracyC}
                      onBlur={accuracyC}
                    >
                      <option value="0">nearest 1</option>
                      <option value="1">to 1dp</option>
                      <option value="2">to 2dp</option>
                      <option value="3">to 3dp</option>
                      <option value="max">Maximum</option>
                    </select>
                  </Select>
                </Control>
              </Field>
              <p className="h5 mt-3">Result: {result}</p>
            </FlexDiv>
          </Column>
          <Column maxWidth="300px">
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
              </React.Suspense>
            )}
          </Column>
        </Columns>

        <br />
        <h2 className="mt-3">Reverse Percentages Formula</h2>
        <br />
        <h3>Percentage Type Decrease</h3>
        <p>
          To calculate the percentage decrease, determine the percentage of the
          number that you want to decrease. Then subtract the decrease from the
          original number.
          <br />
          Example: A truck is reduced by 10% in price to $ 40,000. What was the
          original price?
          <br />
          <strong>Step 1</strong>: The percentage of the original number is 100
          - 10 = 90%
          <br />
          <strong>Step 2 Convert percent to a decimal:</strong> 90% = 90 ÷ 100 =
          0.9
          <br />
          <strong>Step 3 Divide final number by decimal:</strong> 40,000 ÷ 0.9 =
          $ 44,444
          <br />
        </p>
        <h3>Percentage Type Increase</h3>
        <p>
          To calculate the percentage increase, define the percentage of the
          number or price you want to increase, then add the result to the
          original price.
          <br />
          Example: Ramy invests money in cryptocurrency. Five years later, she
          sells them for $7000 at a profit of 25% of their original value. How
          much did she spend on the cryptocurrency?
          <br />
          <strong>Step 1</strong>: The percentage of the original number is 100
          + 25 = 125%
          <br />
          <strong>Step 2 Convert percent to a decimal:</strong> 125% = 125 ÷ 100
          = 1.25
          <br />
          <strong>Step 3 Divide final number by decimal:</strong> 7000 ÷ 1.25 =
          5600
          <br />
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default RevercePercentageCalculator
